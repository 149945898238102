<script>
  import CopyButton from '../copy-button/copy-button.svelte';
  export let content = '';
</script>

<div>
  <code
    class="flex flex-row items-center justify-between rounded-lg bg-space-black text-white font-mono text-xl p-4 break-all"
  >
    <p class="pr-2">
      {content}
    </p>
    <CopyButton label="Copy installation instructions" {content} />
  </code>
</div>
